import styled from 'styled-components';

import bobIcon from '@/shared/assets/bob-token.png';
import ohmIcon from '@/shared/assets/ohm-token.png';
import wglmrIcon from '@/shared/assets/wglmrIcon.png';
import axlIcon from '@/shared/assets/axl-token.png';
import rethIcon from '@/shared/assets/reth-token.png';
import ghoIcon from '@/shared/assets/gho-token.png';
import cbethIcon from '@/shared/assets/cbeth-token.png';
import lrethIcon from '@/shared/assets/icons/lrteth.svg';
import pondIcon from '@/shared/assets/icons/marlin-pond.svg';
import ibtcIcon from '@/shared/components/LrtIcon/iBTCIcon.svg';
import unibtcIcon from '@/shared/components/LrtIcon/uniBTCIcon.svg';
import wstUSRIcon from '@/shared/components/LrtIcon/wstUSRIcon.svg';

import { SpriteIcon } from '../SpriteIcon';

type Props = {
  className?: string;
  dense?: boolean;
  icons: string[];
  size?: number;
  indent?: boolean;
};

const CLASS_NAME = 'TOKEN_ICON';

const StyledIconList = styled.div`
  display: inline-flex;

  .${CLASS_NAME} {
    margin-right: ${({ $dense, size }: { $dense: boolean; size: number }) =>
      $dense ? `-${size / 4}rem` : `-${size / 4}rem`};
  }

  padding-right: ${({ $indent }: { $indent: boolean }) => ($indent ? '5px' : 0)};
`;

export const pngIcons: Record<string, string> = {
  BOB: bobIcon,
  OHM: ohmIcon,
  WGLMR: wglmrIcon,
  AXL: axlIcon,
  rETH: rethIcon,
  GHO: ghoIcon,
  cbETH: cbethIcon,
  lrteth: lrethIcon,
  IBTC: ibtcIcon,
  uniBTC: unibtcIcon,
  POND: pondIcon,
  wstUSR: wstUSRIcon,
};

export const IconList: React.VFC<Props> = ({
  className = '',
  dense = false,
  icons,
  size = 3.2,
  indent,
}) => (
  <StyledIconList className={className} $dense={dense} size={size} $indent={indent}>
    {icons.map((icon, index) => {
      if (pngIcons[icon]) {
        return (
          <img
            key={icon}
            src={pngIcons[icon]}
            width={`${size * 10}px`}
            height={`${size * 10}px`}
            alt={`${icon} icon`}
            className={index !== icons.length ? CLASS_NAME : undefined}
            style={{ zIndex: 10 - index }}
          />
        );
      }

      return (
        <span
          className={index !== icons.length ? CLASS_NAME : undefined}
          style={{
            zIndex: 10 - index,
            display: 'flex',
            alignItems: 'center',
            height: 'min-content',
          }}
          key={icon}
        >
          <SpriteIcon icon={icon} size={size} />
        </span>
      );
    })}
  </StyledIconList>
);
