import styled from 'styled-components';

import { breakpoints } from '@/shared/styles';

type Props = {
  className?: string;
  main?: boolean;
  children: React.ReactNode;
};

const StyledContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  max-width: 1136px;
  margin: 0 auto;
  width: 100%;
  justify-content: center;

  padding: 2.4rem 1.6rem;
  gap: 2.4rem;
  @media (min-width: ${breakpoints.tabletXL}px) {
    gap: 3.2rem;
    padding: 4rem 0 7rem;
    flex-direction: row;
  }
`;

const NewPageContainer: React.FC<Props> = ({ children, className, main }) => (
  <StyledContainer as={main ? 'main' : 'div'} className={className}>
    {children}
  </StyledContainer>
);

export { NewPageContainer };
