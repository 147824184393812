import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  polygonZkEvm,
  moonbeam,
  base,
  holesky,
} from 'wagmi/chains';
import {
  okxWallet,
  metaMaskWallet,
  walletConnectWallet,
  coinbaseWallet,
  rabbyWallet,
  safeWallet,
  trustWallet,
  ledgerWallet,
  phantomWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { fallback, http } from 'wagmi';

import { rpc } from '@/core/config';

const projectId = '28255f9da68a744feafb2e6412aa7024';

export const chains = [
  mainnet,
  polygon,
  optimism,
  arbitrum,
  polygonZkEvm,
  moonbeam,
  base,
  holesky,
] as const;

export const config = getDefaultConfig({
  appName: 'Mellow',
  projectId,
  chains,
  pollingInterval: 5000,
  transports: {
    [mainnet.id]: fallback(rpc.ethereum.map(({ url }) => http(url))),
    [polygon.id]: fallback(rpc.polygon.map(({ url }) => http(url))),
    [optimism.id]: fallback(rpc.optimism.map(({ url }) => http(url))),
    [arbitrum.id]: fallback(rpc.arbitrum.map(({ url }) => http(url))),
    [polygonZkEvm.id]: fallback(rpc.polygonzkevm.map(({ url }) => http(url))),
    [moonbeam.id]: fallback(rpc.moonbeam.map(({ url }) => http(url))),
    [base.id]: fallback(rpc.base.map(({ url }) => http(url))),
    [holesky.id]: fallback(rpc.holesky.map(({ url }) => http(url))),
  },
  wallets: [
    {
      groupName: 'Recommended',
      wallets: [
        okxWallet,
        metaMaskWallet,
        walletConnectWallet,
        coinbaseWallet,
        rabbyWallet,
        safeWallet,
        trustWallet,
        ledgerWallet,
        phantomWallet,
      ],
    },
  ],
});
