import { safeJSONParse } from './format';

export const setToLocalStorage = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStorage = <T>(key: string) => {
  const value = window.localStorage.getItem(key);

  return value ? safeJSONParse<T>(value) : undefined;
};
