import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as LogoSVG } from './logo_color.svg';

type Props = {
  className?: string;
  to?: string;
};

const StyledLogo = styled(Link)`
  align-items: center;
  display: inline-flex;
  flex-flow: row nowrap;
  text-decoration: none;
`;

const NewLogo: React.FC<Props> = ({ className, to }) => (
  <StyledLogo className={className} to={to}>
    <LogoSVG />
  </StyledLogo>
);

export { NewLogo };
