/* eslint-disable no-restricted-globals */
import { createContext, useState, useContext, PropsWithChildren } from 'react';

import { Locale } from '@/shared/types';
import { safeJSONParse } from '@/shared/utils';

type SessionConfig = {
  showBanner: boolean;
};

type CookiesConsent = {
  timestamp: number;
  all?: boolean;
};

type PersistentConfig = {
  cachedProvider: string;
  locale: Locale;
  cookiesConsent: CookiesConsent;
};

type LocalConfig = {
  appError: string;
};

type AppConfig = SessionConfig & PersistentConfig & LocalConfig;

type AppConfigContextType = AppConfig & { updateAppConfig: (c: Partial<AppConfig>) => void };

const initialSessionConfig: SessionConfig = {
  showBanner: true,
};

const initialPersistentConfig: PersistentConfig = {
  cachedProvider: '',
  locale: 'en-US',
  cookiesConsent: { timestamp: 0 },
};

const initialLocalConfig: LocalConfig = {
  appError: '',
};

const initialConfig: AppConfig = {
  ...initialPersistentConfig,
  ...initialSessionConfig,
  ...initialLocalConfig,
};

const storageKey = 'mellow:view-config';

const getStoredConfig = (): AppConfig => {
  const sessionConfigString = sessionStorage.getItem(storageKey);
  const sessionConfig: SessionConfig = sessionConfigString
    ? { ...initialSessionConfig, ...(safeJSONParse(sessionConfigString) || {}) }
    : initialSessionConfig;
  const persistentConfigString = localStorage.getItem(storageKey);
  const persistentConfig: PersistentConfig = persistentConfigString
    ? { ...initialPersistentConfig, ...(safeJSONParse(persistentConfigString) || {}) }
    : initialPersistentConfig;

  return { ...sessionConfig, ...persistentConfig, ...initialLocalConfig };
};

const storeConfig = (config: AppConfig) => {
  const { cachedProvider, locale, showBanner, cookiesConsent } = config;
  const sessionConfig: SessionConfig = { showBanner };
  const persistentConfig: PersistentConfig = { cachedProvider, locale, cookiesConsent };

  sessionStorage.setItem(storageKey, JSON.stringify(sessionConfig));
  localStorage.setItem(storageKey, JSON.stringify(persistentConfig));
  initialLocalConfig.appError = config.appError;
};

export const AppConfigContext = createContext<AppConfigContextType>({
  ...initialConfig,
  updateAppConfig: () => null,
});

export const AppConfigProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const storedConfig = getStoredConfig();
  const [config, setConfig] = useState<AppConfig>(storedConfig);

  const updateAppConfig = (updatedProps: Partial<AppConfig>) => {
    const mergedConfig = { ...getStoredConfig(), ...updatedProps };

    const changeCount = (Object.keys(config) as (keyof AppConfig)[]).reduce(
      (acc: number, key: keyof AppConfig) =>
        config[key] !== mergedConfig[key] ? acc + 1 : acc + 0,
      0,
    );

    if (changeCount > 0) {
      setConfig(mergedConfig);
      storeConfig(mergedConfig);
    }
  };

  return (
    <AppConfigContext.Provider value={{ ...config, updateAppConfig }}>
      {children}
    </AppConfigContext.Provider>
  );
};

export const useAppConfig = () => useContext(AppConfigContext);
