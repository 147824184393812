import styled from 'styled-components';

import { useTheme, Theme } from '@/services/theme';
import { LANDING_DOMAIN } from '@/core/config';
import { NewLogo } from '@/shared/components/Logo/NewLogo';

const StyledHeader = styled.header`
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.gray10};
  border-bottom: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.gray8};
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2.4rem;
  height: 7.9rem;
  margin: 0 auto;
  max-width: 1180px;
  padding: 0 1.6rem;
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
`;

const EmptyHeader: React.VFC = () => {
  const { theme } = useTheme();

  return (
    <StyledHeader theme={theme}>
      <StyledContainer>
        <StyledLink href={LANDING_DOMAIN}>
          <NewLogo />
        </StyledLink>
      </StyledContainer>
    </StyledHeader>
  );
};

export { EmptyHeader };
