import styled from 'styled-components';

import { Theme, useTheme } from '@/services/theme';
import { NewPageContainer } from '@/shared/components/PageContainer/NewPageContainer';
import { Typography } from '@/shared/components/Typography';

import { EmptyHeader } from './EmptyHeader';
import { ReactComponent as RestrictedIcon } from './restricted-icon.svg';

const Background = styled.div`
  background: ${({ theme }: { theme: Theme; $isRestake: boolean }) => theme.colors.background.dark};
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  transition: filter 250ms;
`;

const StyledLink = styled.a`
  color: ${({ $theme }: { $theme: Theme }) => $theme.colors.text.primary};
  font-family: ${({ $theme }: { $theme: Theme }) => $theme.fonts.main};
  line-height: 1.6;
  text-decoration: underline solid
    ${({ $theme }: { $theme: Theme }) => $theme.colors.text.underline};
  text-underline-offset: 0.35em;

  &:focus,
  &:hover {
    text-decoration-color: ${({ $theme }: { $theme: Theme }) => $theme.colors.text.primary};
  }
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 70vh;
  margin-top: -50px;
`;

const StyledTitle = styled(Typography)`
  display: inline-block;
  padding-top: 35px;
  padding-bottom: 25px;
  text-align: center;
`;

const StyledDescr = styled(Typography)`
  text-align: center;
`;

const IpRestrictedPage: React.FC = () => {
  const { theme } = useTheme();

  return (
    <>
      <EmptyHeader />

      <Background theme={theme}>
        <NewPageContainer main>
          <StyledContainer>
            <RestrictedIcon />
            <StyledTitle fontFamily="gramatika" variant="h1">
              Your IP is restricted
            </StyledTitle>
            <StyledDescr fontFamily="gramatika" variant="body1">
              Please check{' '}
              <StyledLink
                href="/Mellow-Terms-of-Service.pdf"
                target="_blank"
                rel="noreferrer"
                $theme={theme}
              >
                Terms of service
              </StyledLink>
            </StyledDescr>
          </StyledContainer>
        </NewPageContainer>
      </Background>
    </>
  );
};

export { IpRestrictedPage };
